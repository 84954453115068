import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { NgIf } from '@angular/common';
import { ProgressBarModule } from 'primeng/progressbar';
import { LoadingService } from '../loading.service';
import { toSignal } from '@angular/core/rxjs-interop';

/** Display the global loading bar in the header when the app is loading. */
@Component({
  selector: 'padspin-header-loading-bar',
  standalone: true,
  imports: [NgIf, ProgressBarModule],
  template: `
    <p-progressBar
      *ngIf="isLoading()"
      mode="indeterminate"
      class="progress-bar"
      [style]="{ height: '4px' }"
    ></p-progressBar>
  `,
  styleUrls: ['./header-loading-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderLoadingBarComponent {
  private readonly isLoadingService = inject(LoadingService);
  protected isLoading = toSignal(this.isLoadingService.isLoading());
}

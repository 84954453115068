import { CapacitorGetPlatformService } from '../capacitor-get-platform.service';

/**
 * Detect the platform before instantiating tracking services.
 *
 * This factory enables us to decide if we should instantiate tracking
 * services without actually instantiating them.
 *
 * The original plan was to instantiate the tracking services and disable
 * them as soon as we check the platform, but the tracking services send
 * Telemetry immediately when instantiated, which the Apple App Store detects
 * (and rejects the app).
 *
 * Returns true when the platform is web, false otherwise.
 *
 * This factory depends on the CapacitorGetPlatformService, so include it in
 * the `deps` array in your providers array:
 * ```ts
 * {
 *   providers: [{
 *     provide: COLLECTION_ENABLED,
 *     useFactory: enableCollectionOnWebPlatformFactory,
 *     deps: [CapacitorGetPlatformService],
 *   }]
 * }
 * ```
 */
export const enableCollectionOnWebPlatformFactory = () => {
  return (capacitorGetPlatform: CapacitorGetPlatformService): boolean =>
    capacitorGetPlatform.getPlatform() === 'web';
};

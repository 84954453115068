import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FooterService } from '../footer.service';
import { VersionService } from '../version.service';
import { RouterModule } from '@angular/router';
import { HeaderModule } from '../header/header.module';
import { SideNavModule } from '../side-nav/side-nav.module';
import { FooterModule } from '../footer/footer.module';
import { FrontendRoutingModule } from './frontend-routing.module';
import { DEFAULTS } from '@angular/fire/compat/remote-config';
import {
  COLLECTION_ENABLED,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/compat/analytics';
import { HeaderLoadingBarComponent } from '../header-loading-bar/header-loading-bar.component';
import { NgIf } from '@angular/common';
import { CapacitorGetPlatformService } from '../capacitor-get-platform.service';
import { enableCollectionOnWebPlatformFactory } from './enable-collection-on-web-platform-factory';

@Component({
  selector: 'padspin-frontend',
  template: `
    <padspin-header></padspin-header>
    <padspin-side-nav></padspin-side-nav>
    <section class="content">
      <padspin-header-loading-bar></padspin-header-loading-bar>
      <router-outlet></router-outlet>
    </section>
    <padspin-footer *ngIf="isFooterVisible()"></padspin-footer>
  `,
  styleUrls: ['./frontend.component.scss'],
  standalone: true,
  imports: [
    HeaderModule,
    SideNavModule,
    FooterModule,
    HeaderLoadingBarComponent,
    FrontendRoutingModule,
    NgIf,
    RouterModule,
  ],
  providers: [
    {
      provide: DEFAULTS,
      useValue: { show_book_a_pad_form_before_register_form: true },
    },
    ScreenTrackingService,
    UserTrackingService,
    {
      provide: COLLECTION_ENABLED,
      useFactory: enableCollectionOnWebPlatformFactory,
      deps: [CapacitorGetPlatformService],
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FrontendComponent implements OnInit {
  private readonly footerService = inject(FooterService);
  private readonly versionService = inject(VersionService);

  protected isFooterVisible = toSignal(this.footerService.getVisibility(), {
    initialValue: true,
  });

  async ngOnInit(): Promise<void> {
    await this.versionService.checkMinVersion();
  }
}
